<template>
  <v-container v-if="$store.getters.getEditUpdateStatus"
                               class="container-edit-progress">
    <v-row>
      <v-col cols="12" class="text-center">
        <v-progress-circular

          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'WaitDialog',
  props: [],
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>

</style>
